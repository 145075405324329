//
// Close icons
// --------------------------------------------------


.close {
  float: right;
  font-size: 25px;
  line-height: 1;
  color: $close-color;
    background: #000;
    border-radius: 1em;
    width: 1em;
    height: 1em;

  &:hover,
  &:focus {
    color: $close-color;
    text-decoration: none;
    cursor: pointer;
    @include opacity(.5);
  }
    span{
        margin-top: -0.25em;
        display: block;
    }

  // [converter] extracted button& to button.close
}

// Additional properties for button version
// iOS requires the button element instead of an anchor tag.
// If you want the anchor version, it requires `href="#"`.
// See https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile
button.close {
  padding: 0;
  cursor: pointer;
  border: 0;
  -webkit-appearance: none;
}
