.recruit{
    .recruit-hero{
        position: relative;
        max-width: 1200px;
        margin: 0 auto;
        >img{
            width: 100%;   
        }
        &:after{
            content:"";
            display: none;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 100px;
            background: linear-gradient(to right, rgba(255,255,255,0) 0%,#fff 100%);

        }
        @media screen and (min-width: 1200px) {
            &:after{
                display: block;
            }
        }

        .hero-text{
            position: absolute;
            width: 25%;
            min-width: 20em;
            left: 10%;
            top: 0;
            bottom: 0;
            h2{
                color: $brand-primary;
                font-size: 30px;
                width: 170%;
            }
            p:last-child{
                margin-bottom: 10px;
            }
            @media screen and (max-width: $screen-sm-max) {
                &{
                    left: 20px;
                    width: 51%;
                    h2{
                        width: 120%;
                    }
                }
            }
            @media screen and (max-width: $screen-xs-max) {
                &{
                    position: static;
                    width: 100%;
                    padding: ($grid-gutter-width / 2);
                    h2{
                        margin-top: 0;
                        width: auto;
                    }
                }
            }
        }
        @media screen and (max-width: $screen-xs-max) {
            border-bottom: 1px solid $gray;
        }
        
    }
    .recruit-interview{
        text-align: center;
        h2{
            font-size: 30px;
            color: $brand-primary;
            font-weight: normal;
            overflow: hidden;
        }
        .item{
            display: inline-block;
            width: 25%;
            margin: 0 3% 20px 3%;
            cursor: pointer;
            @media screen and (max-width: $screen-xs-max) {
                width: 47.5%;
                margin: 0 0 20px 0;
                display: block;
                float: left;
                &:nth-child(odd){
                    margin-right: 5%;
                }
            }

            &:hover{
                .img-circle{
                    //background: radial-gradient(ellipse at center, #3e8acc 67%,#387cb7 100%);
                    border: 8px solid #3e8acc;
                }
            }
            h3{
                color: $brand-primary;
                font-weight: normal;
                margin-top: 15px;
                margin-bottom: 10px;
                font-size: 18px;
            }
            p{
                margin-bottom: 10px;
                color: #000;
            }
            >span{
                color: $brand-primary;
            }
            span{
                vertical-align: middle;
            }
            .img-circle{
                //background: radial-gradient(ellipse at center, #d8d8d8 67%,#000 100%);
                border: 8px solid #d8d8d8;
                //padding: 8px;
                width: 138px;
                height: 138px;
            }
        }        
    }
    .recruit-photos{
        background: $brand-secondary;
        text-align: center;
        color: #fff;
        padding-bottom: 40px;
        
        h2{
            font-size: 30px;
            font-weight: normal;
            overflow: hidden;
        }
        .item{
            width: 25%;
            float: left;
            position: relative;
            cursor: pointer;
            div{
                position: absolute;
                top: 9px;
                left: 9px;
                right: 9px;
                bottom: 9px;
                background: rgba(0,0,0,.5);
                transform: scale(0);
                visibility: hidden;
                &:before{
                    content: "";
                    height: 100%;
                    vertical-align: middle;
                    width: 0px;
                    display: inline-block;
                }
                p{
                    display: inline-block;
                    text-align: center;
                    margin-bottom: 0;
                    vertical-align: middle;
                    color: #fff;
                    line-height: 2;
                    
                }
            }
            
            &:hover,
            &:active{
                div{
                    transition:transform .2s;
                    visibility: visible;
                    transform: scale(1);
                }
            }
            
        }
        @media screen and (max-width: $screen-xs-max) {
            padding-bottom: 10px;
            .item{
                width: 50%;
            }
        }
    }
    .recruit-info{
        padding-top: 30px;
        .table{
            th,td{
                border-top: 0;
                border-bottom: 1px solid $table-border-color;
            }
            th{
                white-space: nowrap;
            }
        }
        .btn{
            margin: 30px auto;
            display: block;
            width: 50%;
            min-width: 280px;
        }
    }
}
.modal-interview,
.modal-phs{
    .close{
        position: absolute;
        top: 5px;
        right: 5px;
    }
}
.modal-interview{
    
    img{
        max-width: 100%;
    }
    .modal-header{
        z-index: 2;
        position: relative;
        padding: 1px 0 10px 0;
        margin: 19px 20px 0 20px;
        margin-top: -30px;
        overflow: hidden;
        .img-circle{
            //background: radial-gradient(ellipse at center, #d8d8d8 67%,#000 100%);
            //padding: 8px;
            border: 8px solid #d8d8d8;
            width: 118px;
            height: 118px;
            float: left;
            margin-right: 10px;
        }
        h1{
            color: $brand-primary;
            font-weight: normal;
            margin-top: 45px;
            margin-bottom: 5px;
        }
        p{
            margin-bottom: 0;
        }
        @media screen and (max-width: $screen-xs-max) {
            margin-top: -50px;
            .img-circle{
                width: 70px;
                height: 70px;
                padding: 5px;
                float: none;
            }
            h1{
                margin-top: 5px;
            }
            
        }
    }
    .modal-body{
        padding: 20px;
        p:last-child{
            margin-bottom: 0;
        }
    }
}
.modal-phs{
    width: auto;
    max-width: 90%;
    .modal-content{
        img{
            max-width: 100%;
            height: auto;
        }
        padding: 10px;
    }
    
}