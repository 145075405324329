.content-wrapper{
    margin-top: 30px;
    margin-bottom: 30px;
}

.sidemenu{
    list-style: none;
    padding: 0;
    li{
        border-bottom: 1px solid #999;
        a{
            padding: 8px 0;
            display: block;
            color: #999;
            &:hover,
            &:focus{
                color: $brand-primary;
                background: lighten($brand-primary,60%);
                text-decoration: none;
            }
        }
        span{
            float: right;
            line-height: 20px;
            margin-right: 10px;
        }
        &.active{
            a{
                color: $brand-primary;
                font-weight: bold;
            }
            span{
                font-weight: normal;
            }
        }
    }
}

#footer{
    border-top: 2px solid $brand-primary;
    .banners{
        background: #dfe6ed;
        padding: 25px 0 2px 0;
        li{
            text-align: center;
            margin-bottom: 10px;
            img{
                max-width: 100%;
                height: auto;
            }
        }
    }
    .footer-nav{
        background: #d8d8d8;
        border-top: 1px solid #a5a5a5;
        ul{
            list-style: none;
            margin-bottom:0;
            li{
                display: inline-block; 
                font-size: 12px;
                margin-right: 30px;
                padding: 10px 0 0 0;
            }
            a{
                color: #000;
            }
            .pagetop{
                float: right;
                margin-right: 0;
                padding: 0;
                a{
                   display: block;
                    color: #fff;
                    background: #a5a5a5;
                    padding: 5px 10px;
                    font-size: 18px;
                }
            }
        }
        @media screen and (max-width: $screen-xs-max) {
            .container{
                padding: 0;
            }
            ul{
                text-align: center;
                li{
                    margin: 0 10px;
                }
                a{
                    color: #000;
                    font-size: 13px;
                }
                .pagetop{
                    float: none;
                    display: block;
                    margin: 10px 0 0 0;
                    width: 100%;
                    a{
                        padding: 0;
                        font-size: 24px;
                    }
                }
            }
        }
    }
    .copy{
        background: $brand-primary;
        padding-top: 10px;
        padding-bottom: 5px;
        p{
            color: #fff;
            text-align: right;
            margin-bottom: 0;
            font-size: 12px;
        }
    }
}


.news-list{
    list-style: none;
    padding: 0;
    margin: 0;
    li{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        border-top: 1px solid #999;
        padding: 8px 0 0 0;
        margin-top: 8px;
        &:first-child{
            border-top: 0;
            padding-top: 0;
            margin-top: 0;
        }
    }
    time{
        display: inline;
        margin-right: 10px;
    }
    .dtl{
        display: inline;
    }
}