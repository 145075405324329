@font-face {
	font-family: 'icomoon';
	src:url('../fonts/icomoon.eot?-3bhib0');
	src:url('../fonts/icomoon.eot?#iefix-3bhib0') format('embedded-opentype'),
		url('../fonts/icomoon.woff?-3bhib0') format('woff'),
		url('../fonts/icomoon.ttf?-3bhib0') format('truetype'),
		url('../fonts/icomoon.svg?-3bhib0#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-arrow1-b:before {
	content: "\e600";
}

.icon-arrow1-l:before {
	content: "\e601";
}

.icon-arrow1-r:before {
	content: "\e602";
}

.icon-arrow1-u:before {
	content: "\e603";
}

.icon-arrow2-b:before {
	content: "\e604";
}

.icon-arrow2-l:before {
	content: "\e605";
}

.icon-arrow2-r:before {
	content: "\e606";
}

.icon-arrow2-u:before {
	content: "\e607";
}

.icon-arrow3-b:before {
	content: "\e608";
}

.icon-arrow3-l:before {
	content: "\e609";
}

.icon-arrow3-r:before {
	content: "\e60a";
}

.icon-arrow3-u:before {
	content: "\e60b";
}

.icon-arrow4-d:before {
	content: "\e60c";
}

.icon-arrow4-l:before {
	content: "\e60d";
}

.icon-arrow4-r:before {
	content: "\e60e";
}

.icon-arrow4-u:before {
	content: "\e60f";
}

.icon-close:before {
	content: "\e610";
}

.icon-home:before {
	content: "\e611";
}

.icon-mail:before {
	content: "\e612";
}

.icon-note:before {
	content: "\e613";
}

.icon-pencil:before {
	content: "\e614";
}

.icon-person:before {
	content: "\e615";
}

