//-----------------------------------------------------------------------------------------
//記事用汎用スタイル
//-----------------------------------------------------------------------------------------
#main{
    article{
        @include clearfix();
    }
    figure{
        img{
            display: block;
        }
    }
    img{
        max-width: 100%;
        height: auto;
    }

    figcaption{
        font-size: 12px;
        margin: 5px 0;
    }
    .alignleft{
        float: left;
        margin-right: 15px;
        margin-bottom: 10px;
        text-align: right;
    }
    .alignright{
        float: right;
        margin-left: 15px;
        margin-bottom: 10px;
        text-align: left;
    }
    .aligncenter {
        display: block;
        margin: 0 auto;
        margin-bottom: 10px;
        text-align: center;
    }
    img.aligncenter,
    .aligncenter img{
        margin: 0 auto;
    }
    .clear{
        clear: both;
    }
    .sns{
        overflow: hidden;
        >*{
            display: inline-block;
            margin-bottom: 5px;
            vertical-align: top;
        }
        .twitter-share-button{
            width: 110px !important;
        }
        #___plusone_0{
            width: 80px !important;
            vertical-align: top !important;
        }
    }
}

.pager {


    .previous a{
        float: left;
        padding: 5px 14px;
        background-color: $pager-bg;
        border: 1px solid $pager-border;
        border-radius: $pager-border-radius;
        text-decoration: none;
    }
    .next a {
        float: right;
        padding: 5px 14px;
        background-color: $pager-bg;
        border: 1px solid $pager-border;
        border-radius: $pager-border-radius;
        text-decoration: none;
    }
    overflow: hidden;
    
}

body.blog .heading3 {
    overflow: hidden;
    
    .b_time {
        float: right; 
    }
}


//-----------------------------------------------------------------------------------------
//見出し
//-----------------------------------------------------------------------------------------
.heading1 {
    border-top: 5px solid $brand-primary;
    border-bottom: 2px solid $gray-lighter;
    font-weight: bold;
    color: $brand-primary;
    padding: 14px 0;
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 25px;
}

.heading2,
.heading3 {
    background: $gray-lightest;
    border-left: 12px solid $brand-secondary;
    font-weight: bold;
    color: $brand-primary;
    padding: 3px 0 3px 20px;
    margin-bottom: 20px;
    font-size: 19px;
    position: relative;
    &:before{
        content:"";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 12px;
        background: $brand-primary;
    }
}

.heading3{
    background: transparent;
    padding: 0 0 0 16px;
    border-left-width: 10px;
    font-size: 18px;
    &:before{
        width: 10px;
    }
}

.heading4 {
    color: $brand-primary;
    font-size: 16px;
    font-weight: bold;
}
.heading5{
    font-size: 16px;
    font-weight: bold;
    color: #4c4c4c;
    padding-left: 1em;
    &:before{
        content:"\0025a0";
        color: $brand-primary;
        vertical-align: 10%;
        width: 1em;
        display: inline-block;
        margin-left: -1em;
    }
}

.heading-side{
    background: $brand-primary;
    color: #fff;
    border-left: 10px solid $brand-secondary;
    margin: 0;
    font-size: 20px;
    padding: 12px 10px;
}

////-----------------------------------------------------------------------------------------
////ボタン
////-----------------------------------------------------------------------------------------
.content-link-r{
    text-align: right;
    font-size: 12px;
    margin-bottom: 0;
}

////------------------------------------------------------------------------------------------
///その他
///-----------------------------------------------------------------------------------------

/*googlemap*/
#map_canvas img{
  max-width : none;
}

#map_canvas label{
  width : auto;
  display : inline;
}

.gm-style img{
  max-width : none;
}

.gm-style label{
  width : auto;
  display : inline;
}
/*recruit CEO*/

/*pankuzu*/
ul.breadcrumb{
  list-style: none;
  padding-left:0;
}

ul.breadcrumb li {
  display: inline;
  margin-right: 6px;
}
/*information-archive*/
.information_table .time{
  text-align: left;
}
.information_table .title {
  text-align: left;
}
/*フォーム*/
input[name="element-0"] {
  width:70%;
}
input[name="element-2"] {
  width:70%;
}

/*pager*/
.pager span, .pager a {
    border:1px solid #000;
    padding: 0 10px;
}

.pager .current {
    background-color: #295d89;
    color:white;
}

.pager a {
    text-decoration: none
}

//sidebra
.author_list {
    margin-top: 40px
}

////-----------------------------------------------------------------------------------------
////マージン
////-----------------------------------------------------------------------------------------
/* margin-top */
@for $i from 0 through 6 {
    .mt#{$i*5} {
        margin-top: #{$i*5}px !important;
    }
}
@for $i from 1 through 4 {
    .mt#{$i*-5} {
        margin-top: #{$i*-5}px !important;
    }
}

/* margin-bottom */
@for $i from 0 through 6 {
    .mb#{$i*5} {
        margin-bottom: #{$i*5}px !important;
    }
}
@for $i from 1 through 4 {
    .mb#{$i*-5} {
        margin-bottom: #{$i*-5}px !important;
    }
}
/* margin-left */
@for $i from 0 through 6 {
    .ml#{$i*5} {
        margin-left: #{$i*5}px !important;
    }
}
@for $i from 1 through 4 {
    .ml#{$i*-5} {
        margin-left: #{$i*-5}px !important;
    }
}
/* margin-right */
@for $i from 0 through 6 {
    .mr#{$i*5} {
        margin-right: #{$i*5}px !important;
    }
}
@for $i from 1 through 4 {
    .mr#{$i*-5} {
        margin-right: #{$i*-5}px !important;
    }
}
