#carousel-hero{
    width:100%;
    margin: 0 auto;
    .carousel-inner{
//        max-width: 1400px;
//        margin: 0 auto;
        @media screen and (max-width: $screen-sm-max) {
            padding-bottom: 30px;
        }
        .item{
            .inner{
                max-width: 1400px;
                width: 100%;
                margin: 0 auto;
                position: relative;
                &:before,
                &:after{
                    content:"";
                    display: none;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    width: 100px;
                }
                &:before{
                    left: 0;
                    background: linear-gradient(to right, #fff 0%,rgba(255,255,255,0) 100%);
                }
                &:after{
                    right: 0;
                    background: linear-gradient(to right, rgba(255,255,255,0) 0%,#fff 100%);
                }
                @media screen and (min-width: 1400px) {
                    &:before,
                    &:after{
                        display: block;
                    }
                }
            }
            img{
                max-width: 100%;
                height: auto;
                margin: 0 auto;
            }
            @media screen and (max-width: $screen-md-max) {
                img{
//                    max-width: 120%;
//                    margin-left: -10%;
                }
            }
            .hero-text{
                position: absolute;
                top: 0;
                bottom: 0;
                padding: 15px;
                min-width: 20em;
                width: 30%;
                h1{
                    margin-top: 10px;
                }
                p{
                    text-align: justify;
                }
                
            }
            .hero-text-l{
                left: 10%;
                color: #fff;
                h1{
                    width: 170%;
                }
            }
            .hero-text-r{
                right: 10%;
                filter:progid:DXImageTransform.Microsoft.Gradient(GradientType=0,StartColorStr=#88ffffff,EndColorStr=#88ffffff);/*IE8以下用*/
                background: rgba(255,255,255,.5);
            }
            @media screen and (max-width: $screen-sm-max) {
                .hero-text{
                    position: static;
                    width: 100%;
                    color: #000;
//                    margin-bottom: 25px;
                    h1{
                        width: auto;
                    }
                }
            }
            .btn{
                display: block;
                background: #fff;
                border-color: $brand-primary;
                border-width: 2px;
                color: $brand-primary;
                font-size: 20px;
                font-weight: bold;
                border-radius: 5px;
            }
        }
    }
}
.home{
    .home-introduction{
        margin-top: 30px;
        section{
            margin-bottom: 25px;
        }
        a{
            &:hover,
            &:active{
                 text-decoration: none;   
            }
            
        }
        .heading1{
            font-size: 18px;
            padding: 6px 0 7px 0;
            margin-bottom: 10px;
            span{
                margin-right: 10px;
            }
        }
        p{
            margin-bottom: 10px;
        }
        img{
            width: 100%;
        }
    }
    .home-news{
        .panel-heading{
            a{
                float: right;
                font-size: 12px;
                border: 2px solid $brand-primary;
                padding: 2px 8px;
                background: #fff;
                border-radius: 3px;
            }
        }
    }
    .home-privacy{
        margin-bottom: 25px;
        .inner{
            display: table;
            width: 100%;
            border: 2px solid $brand-primary;
            border-radius: 5px;
            padding: 0;
            overflow: hidden;
        }
        .pmark{
            display: table-cell;
            vertical-align: middle;
            padding: 10px;
            width: 70px;
        }
        p{
            display: table-cell;
            background: #f0f3f6;
            vertical-align: middle;
            padding: 10px;
            font-size: 12px;
        }
    }
}